
import { ref } from "vue";

export const show = ref(false);

export function showDrawer() {
    show.value = true;
}

export function hideDrawer() {
    show.value = false;
}

export const data = {
    openContextSelector: () => {}
};

export function openContextSelector() {
    console.log("Opening context selector...");
    data.openContextSelector();
}
